.divperfile {

    display: block;
    margin: auto;
    height: auto;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 5px #00000033;
    width: 350px;
    background-color: #f3f3f3;
}

.background {
    width: 100%;
    overflow: hidden !important;
    height: 90vh;
    /* Establece la altura para cubrir toda la pantalla */
    background-color: #ffffff;
    /* Color de fondo para el div de fondo */
    display: flex;

}

.inputprofile {
    border-left: solid 2px #0066c0 !important;
    border-radius: 10px;
}

.lblcambiarpass{
    color: #2390f0;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer !important;
}

.lblcambiarpass:hover{
    text-decoration: underline !important;
}
