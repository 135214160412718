
.subtexttableRecordatorio {
    padding-top: 3px;
    font-size: 14px;
    margin-bottom: 3px;
    
}

.fondorow{
    border-bottom: solid 1px black;
    border-left: solid 1px black;
    border-right: solid 1px black;
}

.bordertable{
    border: solid 1px black;
}

.SubCatFondo{
    background-color: #accdff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.fonblan{
    border-bottom: solid 1px #626262;
    border-left: solid 1px #626262;
    border-right: solid 1px #626262;
    background-color: #e3e3e3;
}
.fongris{
    border-bottom: solid 1px #626262;
    border-left: solid 1px #626262;
    border-right: solid 1px #626262;
    background-color: whitesmoke;
}

.icoedit{
    margin-top: 7px;
    padding: 3px;
    color: #194ca4;
    font-size: medium;
    height: 25px;
    width: 25px;
    cursor: pointer !important;
    border-radius: 5px;
    margin-right: 2px;

}
.icodelate{
    margin-top: 7px;
    margin-left: 2px;
    padding: 3px;
    color: red;
    font-size: medium;
    height: 25px;
    width: 25px;
    cursor: pointer !important;
    border-radius: 5px;
}
