.sidebar {
    width: 65px;
    height: 100%;
    background-color: #291f6e;
    transition-duration: 0.5s;
    display: flex;
    flex-direction: column;

    .imge {
        display: none;
       
    }

    .hamburger {
        width: 25px;
        height: 25px;
        /*margin-top: 20px;*/
        cursor: pointer;
        color: white !important;
        margin-left: 10px;

    }

    .linksContainer {
        display: flex;
        flex-direction: column;
        margin-top: 80px;
    }
}

.nabvarcssmenu{
    position: relative;
  
    background-color: #578cff;
    height: 60px;
    width: 100%;
    padding-left: 5px;
    border-bottom: solid 3px #578cff ;
  
  
  }

.sidebarOpen {
    cursor: pointer !important;
    width: 215px !important;
    height: 100%;
    background-color: #291f6e;
    transition-duration: 0.4s;

    .linksContainer {
        display: flex;
        flex-direction: column;
        margin-top: 40px;

    }

    a {
        text-decoration: none;
    }

    .hamburger {
        width: 40px;
        height: 40px;
        /*margin-top: 20px;*/
        cursor: pointer;
        color: white !important;
        margin-left: 10px;
    }


    .imge {
        display: block;
        margin: auto;
        height: auto;
        width: 70px;
        margin-top: -10px;
        
    }
    

}
.lblstile, .lblstile_new {
    cursor: pointer !important;
}

.lbltittled{
    cursor: pointer !important;
    color: white;
    font-size: x-large;
    font-weight: bold;
    width: 100%;
    letter-spacing: 0.9px;
    text-align: center;

}

.itemmenu {
    stroke: white !important;
    width: 25px;
    height: 29px;
    cursor: pointer;
    color: white !important;

}



/* Sidebar Cerrada e item Activo */
.normal[aria-current] {
    position: relative;
    width: 60px;
    height: 50px;
    color: #fe4d44;
    background-color: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    path {
        fill: #fe4d44;
    }

    ::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 50px;
        background-color: #fe4d44;
        left: 0;
        top: 0;
    }
}

/* Sidebar Cerrada e item Desactivado */
.normal {
    width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a:hover {
        path {
            fill: white;
        }
    }
}

/* Sidebar Abierta e item Desactivado */
.linkOpen {
    height: 50px;
    display: flex;
    padding-left: 25px;
    align-items: center;
    gap: 15px;
    transition-duration: 0.3s;

    p {
        color: white;
        font-weight: bold;
        font-size: medium;
        padding-top: 2px;
        width: 100px;
        margin-bottom: 0px !important;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* Hovers Sidebar Cerrada */


/* Sidebar Abierta e item Activado */
.linkOpen[aria-current] {
    position: relative;
    height: 50px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    gap: 15px;
    transition-duration: 0.3s;
    background-color: #1f1f1f;

    path {
        fill: #fe4d44;
    }

    ::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 50px;
        background-color: #fe4d44;
        left: 0px;
        top: 0;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p {
        color: #fe4d44;
        font-weight: bold;
        font-size: medium;
        padding-bottom: 2px;
    }

    p::after {
        display: none;
    }
}


.linkOpen:hover {
    background-color: #0471ff;
}











/* Sidebar Cerrada e item Activo */
.normal_new[aria-current] {
    position: relative;
    width: 60px;
    height: 50px;
    color: #fe4d44;
    background-color: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    path {
        fill: #fe4d44;
    }

    ::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 50px;
        background-color: #fe4d44;
        left: 0;
        top: 0;
    }
}

/* Sidebar Cerrada e item Desactivado */
.normal_new {
    width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a:hover {
        path {
            fill: white;
        }
    }
}

/* Sidebar Abierta e item Desactivado */
.linkOpen_new {
    height: 50px;
    display: flex;
    padding-left: 64px;
    align-items: center;
    gap: 15px;
    transition-duration: 0.3s;

    p {
        color: white;
        font-weight: bold;
        font-size: medium;
        padding-top: 2px;
        margin-bottom: 0px !important;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* Hovers Sidebar Cerrada */


/* Sidebar Abierta e item Activado */
.linkOpen_new[aria-current] {
    position: relative;
    height: 50px;
    padding-left: 64px;
    display: flex;
    align-items: center;
    gap: 15px;
    transition-duration: 0.3s;
    background-color: #1f1f1f;

    path {
        fill: #fe4d44;
    }

    ::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 50px;
        background-color: #fe4d44;
        left: 0px;
        top: 0;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p {
        color: #fe4d44;
        font-weight: bold;
        font-size: medium;
        padding-top: 2px;
        margin-bottom: 0px !important;
    }

    p::after {
        display: none;
    }
}

.linkOpen_new_select {
    
    background-color: #5184c7;
}

.linkOpen_new:hover {
    background-color: #5184c7;
}