.nabvarcss {
  position: relative;

  background-color: #578cff;
  height: 60px;
  width: 100%;
  padding-left: 5px;
  border-bottom: solid 3px #578cff;


}

.lblusuarionav {
  position: absolute;
  top: 30%;
  font-size: large;
  font-weight: 500;
  right: 3%;

}

.lbltittledlbl{
  cursor: pointer !important;
  color: white;
  font-size: x-large;
  font-weight: bold;
  width: 100%;
  letter-spacing: 0.9px;
  text-align: center;

}
   
.hamburgerlbl {
  width: 40px;
  height: 40px;
  /*margin-top: 20px;*/
  cursor: pointer;
  color: white !important;
  margin-left: 10px;
}

.lblusuario-estatus {
  font-size: xx-large;
  color: black;
}

.dropdown-toggle::after {
  display: none;
}


.lblperfil {
  font-size: 15px;
  color: black;
  letter-spacing: 0.8px;
  font-weight: bold;
  text-align: center;
}

.lblperfilemail {
  margin-top: -7px;
  font-size: 12px;
  color: black;
  letter-spacing: 0.8px;
  text-align: center;
}

.profile-image {
  margin-top: 12px;
  width: 85px;
  height: 85px;
  padding: 5px;
  object-fit: cover;
  border-radius: 50%;
  border:solid 2px black;
  background-color: #d4d4d4;

}