.modalmessage-footer {
    position: relative;
    height: 30px;
}

.message-alert-cancel {
    position: absolute;
    left: 0px;
    height: 30px;
    width: 100px;
    color: black;
    border-radius: 5px;
    border: solid 2px #51a3ed;
}

.message-alert-ok {
    position: absolute;
    right: 0px;
    height: 30px;
    width: 100px;
    color: black;
    background-color: #51a3ed !important;
    border-radius: 5px;
}

.modalmessage-body {
    width: 90%;
    text-align: center;
}