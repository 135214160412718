.backgrounddiv {
    width: 100%;
    overflow: hidden !important;
    height: auto;
    background-color: transparent !important;
    display: flex;
}

.divperfilediv {

    display: block;
    margin: auto;
    height: auto;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    background-color: #f3f3f3;
}
