.spaciostabla {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.lblcorreo {
    background: #ececec;
    border: solid 1px #797979;
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 8px;
    padding-top: 5px;
}


.btncorreoser {
    text-transform: capitalize !important;
    background: #51a3ed !important;
    border-radius: 8px !important;
    border: solid 0px black;
    width: fit-content;
    font-size: 14px;
    color: black;
    padding-left: 2px;
    padding-right: 6px;
}

.messageCheck {
    position: absolute;
    right: 26px;
    height: 30px;
    width: 100px;
    color: black;
    background-color: #51a3ed !important;
    border-radius: 5px;
}

.btnbtnnerw {
   padding-top: 2px;
    padding-left: 4px;
    padding-right: 4px;
    color: white;
    background-color: #5056ff !important;
    border-radius: 5px;
}