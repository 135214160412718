.premium-card {
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border: 2px solid #0061fe;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
    background: #f9f9f9;
  }
  
  .premium-title {
    font-size: 2em;
    color: #0061fe;
    margin-bottom: 10px;
  }
  
  .premium-description {
    font-size: 16px;
    color: #000;
    margin-bottom: 25px;
  }
  
  .premium-benefits {
    padding-left: 27px !important;
    padding-right: 10px !important;
    list-style: none;
    padding: 0;
    text-align: left;
    margin-bottom: 15px;
  }
  
  .premium-benefits li {
    font-size: 13px;
    margin-bottom: 8px;
    color: #333;
  }
  
  .premium-footer {
    margin-top: 15px;
  }
  
  .premium-price {
    font-size: 2em;
    color: #333;
    font-weight: bold;
    margin: 0;
  }
  
  .premium-duration {
    margin-top: -6px;
    font-size: 1.1em;
    color: #777;
  }
  .lbltitlemodal{
    font-weight: bold !important;
    color: #000 !important;
    font-size: 16px !important;
  }
  
  .premium-btn {
    margin-top: 10px;
    background: #d6d6d6;
    color: #004dcf;
    font-size: 1em;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    border: solid 1px #d6d6d6;
    letter-spacing: 1px;
    transition: background-color 0.3s ease;
  }


  .add-btn{
    background: #004dcf;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    border: solid 1px #004dcf;
    border-radius: 6px;
    cursor: pointer;
    letter-spacing: 1px;
  }

  
  .premium-btn:hover {
    background: #f1f1f1;
    border: solid 1px #7a7a7a;
  }
  

  .modal-content-wrapper {
    padding: 20px;
  }
  
  .modal-content-wrapper h2 {
    margin-bottom: 15px;
  }
  
  .modal-content-wrapper label {
    display: block;
    margin-bottom: 10px;
  }
  
  .modal-content-wrapper input,
  .modal-content-wrapper textarea {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .modal-content-wrapper ul {
    list-style-type: none;
    padding: 0;
  }
  
  .modal-content-wrapper ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .remove-benefit-btn {
    background-color: #ff6060;
    margin-top: -3px !important;
    color: white;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 15px;
  }
  
  .save-btn {
    background-color: #0061fe;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
  }

  .divcolbeni{
    background-color: #ececec;
    color: black;
    border-bottom: solid 1px black;
    padding-top: 10px !important;
    padding-bottom: 1px !important;
    margin-top: -5px;
  
   
  }
  
  .cancel-btn {
    background-color: #ddd;
    color: black;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .save-btn:hover {
    background-color: #004dcf;
  }
  
  .cancel-btn:hover {
    background-color: #bbb;
  }
  
  .txttitless{
  
    border-radius: 8px !important;
    border: solid 2px #004ccfb1 !important;


  }

