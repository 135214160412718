.letra {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.greenbutton {
    text-transform: capitalize !important;
    background: #5a49f8 !important;
    color: white !important;
    border-radius: 30px !important;
    width: 155px;
}

.bluebutton {
    text-transform: capitalize !important;
    background: #51a3ed !important;
    color: black !important;
    border-radius: 30px !important;
    width: 175px;
    font-size: x-large;
    padding: 4px !important;
}

.buttonCats {
    text-transform: capitalize !important;
    background: #51a3ed !important;
    color: black !important;
    border-radius: 13px !important;
    border:solid 0px black;
    width: 90px;
    margin-left: 10px !important;
    margin-right: 5px !important;
    font-size: medium;
    padding: 3px;
}

.buttonCatsBlanco {
    text-transform: capitalize !important;
    border: solid 1px #51a3ed !important;
    background: white !important;
    color: black !important;
    border-radius: 13px !important;
    width: 90px;
    margin-left: 10px !important;
    margin-right: 5px !important;
    font-size: large;
    padding: 3px !important;
}

.contenedorhome {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 93%;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    --bs-btn-padding-x: 0.3rem !important;
    --bs-btn-padding-y: 0.2rem !important;

}

.testimonial-group>.row {
    overflow-x: auto;
    white-space: nowrap;
}

.lbllistat {
    font-size: xx-large;
    font-weight: 500;
    letter-spacing: 1px;
}

.icofechan {
    font-size: x-large;
}

.lblfechan {
    border-radius: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: large;
    margin: 0px !important;
    font-weight: 500;
    display: flex;
    border: solid 2px #51a3ed;
}

.lblhorarios {
    font-size: medium;
    color: black;
    font-weight: 600;
    margin-left: 5px;
    margin-left: 25px;
    margin-right: 15px;
}

.lbllistahorarios {
    margin-left: 7px;
    margin-right: 7px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: large;
    margin-bottom: 0px !important;
    font-weight: 500;
    margin-top: 13px !important;
    border: solid 2px #51a3ed;
}

.slblsearch {
    border: none;
    font-size: medium;
}

.sinpadding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.input-group-text {
    height: 100%;
    border-top: solid 2px #0066c0 !important;
    border-bottom: solid 2px #0066c0 !important;
    border-left: solid 2px #0066c0 !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

@media (max-width: 400px) {
    .form-control {
        min-width: 210px !important;
    }
}

@media (min-width: 401px) {
    .form-control {
        min-width: 240px !important;
    }
}

.form-control {
    border-top: solid 2px #0066c0 !important;
    border-bottom: solid 2px #0066c0 !important;
    border-right: solid 2px #0066c0 !important;
}

.shr {
    margin-top: 10px;
    width: 100% !important;
    background-color: #51a3ed;
    height: 3px !important;
}

.shr_actividades {
    margin-top: 10px;
    width: 100% !important;
    background-color: #74d1f6;
    height: 1px !important;
}

.lblhhoraselect {
    font-size: large;
    font-weight: 700;
}

.icolblhora {
    font-size: xx-large;
}

.divhorizontal {
    position: relative;
    width: 98%;
    margin-left: 10px;
}

.divhorizontaltabla {
    position: relative;
    width: 100%;
    margin: auto;
    display: block;
}

.textocentro {
    text-align: center;
}

.lblhederstabla {
    font-size: medium;
    font-weight: 500;
}

.sub_lblhederstabla {
    font-size: medium;
    font-weight: 400;
}

.lblcolladatos {
    font-size: large;
    font-weight: 400;
    margin-left: 15px;
}

.tamanotitlesmodal {
    font-size: 20px !important;
}

.textocerrarlbl {
    font-size: x-large;
    font-weight: 600;
    text-align: right;
    width: 100%;
}

.fondoscollagetituloactividades {
    background-color: #d4d4d4 !important;
    color: black !important;
    font-weight: 600;
    min-width: 80px;
    font-size: medium;
    padding-right: 25px;
    padding-left: 5px;
    padding-top: 3px;
    border-top-right-radius: 13px;
}

.fondoscollagetitulo {
    background-color: #0066c0 !important;
    color: white !important;
    padding-left: 25px;
    padding-right: 5px;
    padding-top: 3px;
    border-top-left-radius: 13px;
}

.fondoselectmodalactividades {
    background-color: #b9fcbc !important;
    color: black !important;
    border: solid 2px #b9fcbc;
}

.fondoselect {
    background-color: #6dcff6 !important;
    color: black !important;
    border: solid 2px #6dcff6;
}

.margin_letf_actividades {
    margin-left: 50px !important;
}

.altura {
    min-height: 180px !important;
}

.srowactivity {
    width: 95% !important;
    max-width: 1000px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    background-color: #e4eeff;
}

.srowmodalactividades {
    padding-top: 8px;
    padding-bottom: 8px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #51a3ed;
}

.srows {
    min-width: 640px !important;
    padding-top: 8px;
    padding-bottom: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #51a3ed;
}

.srowsmodal {
    /* min-width: 1200px !important; */
    padding-top: 8px;
    padding-bottom: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #51a3ed;
}

.srowancho {
    width: 95%;
    max-width: 1000px;
}

.srowssubcollagebokun {
    padding-top: 3px;
    padding-bottom: 3px;
    width: 95%;
    max-width: 1300px;
    border: solid 1px #bce5ff !important;
}

.srowssubcollagebokunblack {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 95%;
    max-width: 1300px;
    border: solid 1px #a5a5a5 !important;
}

.srowsdetallesbokunBlanco {
    padding-top: 2px;
    padding-bottom: 2px;
    width: 95%;
    color: black !important;
    font-weight: 500 !important;
    max-width: 1300px;
    border: solid 1px #51a3ed !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.srowsdetallesbokunAfiliados {
    padding-top: 3px;
    padding-bottom: 3px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #707070;
    width: 95%;
    color: white !important;
    font-weight: 500 !important;
    max-width: 1300px;
}

.srowsdetallesbokun {
    padding-top: 3px;
    padding-bottom: 3px;
    border-top-right-radius: 5px;
    background-color: #51a3ed;
    width: 95%;
    color: white !important;
    font-weight: 500 !important;
    max-width: 1300px;
}

.srowscollage {
    padding-top: 3px;
    padding-bottom: 3px;
    border-top-left-radius: 5px;
    background-color: #d4d4d4;
    width: 85%;
    margin: auto !important;
}

.srowssubcollage {
    padding-top: 3px;
    padding-bottom: 3px;
    width: 85%;
    margin: auto !important;
}

.srowssubcollagetitulos {
    padding-top: 3px;

    width: 85%;
    margin: auto !important;
}

.txttitlestablenoreservations {
    font-size: large;
    font-weight: 650;
}

.spaciostabla {
    padding-top: 5px;
    padding-bottom: 5px;
}

.icoojo {
    color: #51a3ed;
    font-size: x-large;
}

.btnfiltros {
    background-color: #0066c0;
    color: white;
    font-size: medium;
    border-radius: 10px;
    border: 0px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
}

.btnsincrono {
    border: 0px;
    border-radius: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 25px;
    margin-right: 25px;
    background-color: #0177bf;
    color: white;
    font-weight: 500;
    letter-spacing: 1.5px;
    font-size: medium;
}

.btnsincronoactivity {
    border: 0px;
    border-radius: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 25px;
    margin-right: 25px;
    background-color: #51a3ed;
    color: black;
    font-weight: 500;
    letter-spacing: 1.5px;
    font-size: medium;
}

.iconoupdate {
    padding-right: 3px;
    stroke: white !important;
    color: white !important;
    font-weight: 600;
    font-size: x-large;
}

.iconos_sap {
    margin-right: 10px;
    stroke: green !important;
    color: green !important;
    font-weight: 600;
    font-size: x-large;
}

.iconos_sapB {
    margin-left: 10px;
    stroke: black !important;
    color: black !important;
    font-weight: 600;
    font-size: x-large;
}

.iconos_sapA {
    margin-right: 15px;
    stroke: #2b2b2b !important;
    color: #2b2b2b !important;
    font-weight: 600;
    font-size: x-large;
}

.grenn {
    color: black !important;
    background-color: #d7f9b9;
}

.reed {
    color: black !important;
    background-color: #efdbde;
}

.btnconfirm_disable {
    background-color: #c2c2c2;
    color: white;
    font-size: medium;
    border: solid 2px #c2c2c2;
}

.btnconfirm {
    background-color: #0066c0;
    color: white;
    font-size: medium;
    border: solid 2px #0177bf;
}

.btncancel {
    background-color: #dd5258;
    color: white;

    border: solid 2px #dd5258;
}

.btnestiloact {
    font-size: medium;
    border-radius: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 30px;
    padding-right: 30px;
}

.btnselectsap {
    font-size: medium;
    border-radius: 30px;
    padding-left: 30px;
    padding-right: 30px;

    background-color: #d7f9b9;
    color: black;
    font-size: medium;
    border: solid 2px #44be7f;
}

.divscroll {
    max-height: 650px !important;
    overflow-y: auto !important;
}

.divscrollhome {

    max-height: calc(100vh - 277px);
    overflow-y: auto !important;
}

.icoestatuslista {
    font-size: x-large;
    stroke: green !important;
    color: green !important;
}

.txttitlestable {
    color: black !important;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 15px;
    font-weight: 800;
}

.cursor {
    cursor: pointer !important;
}

.altotalbanew {
    height: 56vh;
    min-height: 56vh;

}

.subtexttable {
    padding-top: 3px;
    font-size: 14px;
    margin-bottom: 3px;
}