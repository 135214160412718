.btnCuentasUser {
    text-transform: capitalize !important;
    background: rgba(81, 163, 237) ;
    border-radius: 10px !important;
    border: solid 0px black !important;
    width: fit-content !important;
    font-size: 14px !important;
    color: black;
    padding-left: 20px !important;
    padding-right: 20px !important;
}


.btnElegir {
    text-transform: capitalize !important;
    border-radius: 10px !important;
    border: solid 0px black !important;
    width: fit-content !important;
    font-size: 14px !important;
    color: black;
    display: block !important;
    margin-left: auto !important;
}

.container {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
}

.txtlabel {
    color: black !important;
    width: 100%;
    text-align: start;
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 5px;
}


.txtcanti:hover, txtcanti::before, .datepicker:hover, .datepicker::before {
    border: solid 2px #4137a4 !important;
}


.inputprofilenew {
    border: solid 2px #4137a4 !important;
    border-radius: 10px;
    color: #000 !important;
}
.lblflotoante {
    color: #51a3ed !important;
    font-size: 30px;
    top: -1px !important;
    left: 15px;
    position: absolute !important;
    font-weight: bold !important;
}

.lblflotoantesigno {
    color: black!important;
    font-size: 20px;
    top: 6px !important;
    left: 35px;
    position: absolute !important;
    font-weight: bold !important;
}

.txtcanti {
    padding-top: 6px !important;
    padding-bottom: 3px !important;
    padding-left: 48px !important;
    font-size: large;
    color: black;
    width: 100%;
    font-weight: bold !important;
    border-radius: 8px;
    border: solid 2px #4137a4;
    text-align: start !important;
}

.datepicker {
    padding-top: 6px !important;
    padding-bottom: 3px !important;
    border-radius: 8px;
    border: solid 2px #4137a4;
    flex: 1;
    color: black !important;
    text-align: right !important;
    width: 100% !important;
    font-size: medium;
}

.react-datepicker-wrapper {
    width: 100% !important;
}


.txtcantidaFrecuancia{
    width: 35px;
    text-align: center;
    border-top: solid 0px black;
    border-left: solid 0px black;
    border-right: solid 0px black;
    border-bottom: solid 2px #636363;
    background-color: white;
    font-size: 14px;
    font-weight: bold;
}

.form-check{
   
    color: black !important;
    font-size: 15px !important;
    font-weight: 700;
}


.form-check-input{
    border: solid 2px #4137a4;
}

.divdiracion, .divdiracionTipo{
    border: solid 2px #4137a4;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
}

.divdiracionTipo{
    padding-top: 15px;
    border: solid 2px gray !important;
}

.lbltduracion{
    padding-top: 15px;
    padding-bottom: 5px;
    width: 100%;
    text-align: start;
    font-weight: bold;
    font-size: 16px;
}

.lbltfree{
    
    text-align: start;
    display: flex;
    align-items: flex-start;
    gap: 8px;
}