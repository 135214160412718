body {
  font-family: "Axiforma_regular" !important;
  height: 100vh;
}

.letratitulos {
  font-family: "Axiforma_bold" !important;
}

.letranormal {
  font-family: "Axiforma_regular";
}

@font-face {
  font-family: "Axiforma_bold";
  src: url("./../public/Axiforma/Axiforma_Bold.otf") format("opentype");
}

@font-face {
  font-family: "Axiforma_regular";
  src: url("./../public/Axiforma/Axiforma_Light.otf") format("opentype");
}

.css-1eyq96w-MuiPaper-root{
  border-radius: 20px !important;
}
.css-12xpohk-MuiInputBase-root{
  background-color: white !important;
  border-radius: 20px !important;
  border: solid 1px #1e1e1e !important;
  color: black !important;
}
.MuiInput-root {
  border-bottom: 0px !important;
}

.MuiInput-root:hover {
  border-bottom: 0px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.icoslider {
  cursor: pointer !important;
  font-size: x-large;
  color: white !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.eve {
  background-color: #b4e2ff;
}
.low {
  background-color: #f2f8fc;
}
.mostrar_cursor{
  cursor: pointer !important;
}
.inputslogin {
  height: 45px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  border: solid 2px white;
  padding-left: 10px;
  font-size: medium !important;
  width: 100%;
  background-color: white !important;
}
.rowdaaa {
  min-height: 100vh;
  min-width: 100vw;
}

:root {
  overflow-x: clip !important;
}

.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}



