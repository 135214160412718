

  .lbltitulologin {
    font-size: large;
    font-weight: 400;
    color: white;
    padding-bottom: 5px;
  }

  .lbltitlesModal{
    color: black; 
    font-size: large !important;
    margin-top: 10px;
    text-align: left;
    font-weight: bold;
  }
  
  .lbldespass{
    color: black; 
    font-size: medium;
    text-align: left;
  }

.textocerrarlbl{
  color: black;
  font-weight: bold;
}

.divconpass{
  width: 95%;
  margin: auto;
}

.error-message {
  text-align: center;
  width: 100%;
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.edit-message {
  text-align: center;
  width: 100%;
  color: #6657e7;
  font-size: 15px;
  margin-top: 10px;
  font-weight: bold;
}

.txtemailpass{
  background-color: white;
  border-radius: 15px;
  border: solid 1px black;
  width: 80%;
  display: block;
  padding: 8px;
  margin: auto;
  text-align: center;
  color: black;
  font-size: medium;
}

.btnrespass{
    width: 120px !important;
    margin: auto !important;
    border: 1px solid #4137a4 !important;
    display: block !important;
    border-radius: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    background-color: #4137a4 !important;
    color: white !important;

}

