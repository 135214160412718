.nuevocolorfondo{
    background-color: #1e1e1e !important;
    color: white !important;
    cursor: pointer;
}

.btncorreoserLista{
    text-transform: capitalize !important;
    background: #51a3ed !important;
    border-radius: 10px !important;
    border: solid 0px black;
    width: fit-content !important;
    font-size: 14px !important;
    color: black !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-left: 10px !important;
}